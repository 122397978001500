import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import {
  Box,
  Typography,
  Container,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  TableCell
} from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import formula from "../assets/ExtraPages/formula.png"

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em"
    },
    marginTop: "10em"
  },
  linksStyles: {
    textDecoration: "underline",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64"
    }
  },
  table: {
    minWidth: 700
  }
}))
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)

function createData(name, cost) {
  return { name, cost }
}

const rows = [
  createData("Solicitud de créditos", "Gratis"),
  createData("Análisis de la aplicación y de la documentación", "Gratis"),
  createData(
    "Comisión de Gestió",
    "Variable entre el 0.1% y el 1% del monto solicitado, enfunción de la calificación crediticia y aplicable para cada uno de los plazos del financiamiento."
  ),
  createData("Elaboración del Contrato", "Gratis"),
  createData("Formalización y Firma del Contrato", "Gratis"),
  createData(
    "Comisión de Apertura",
    "Variable entre el 3.5% y el 30% del monto solicitado, dependiendo de la calificación crediticia y del plazo del financiamiento"
  ),
  createData("Comisión por pago adelantado", "Gratis"),
  createData(
    "Intereses moratorios",
    "Por pagos fuera de término. Es el 100% adicional sobre TNA y se calcula por día de atraso (una vez transcurridos 5 días de la fecha establecida)"
  ),
  createData(
    "Intimidaciones por falta de pago",
    "Recupero del costo por las notificaciones legales por las faltas de pago"
  ),
  createData("Comisión por pago retrasado", "Gratis")
]
const rowsInvest = [
  createData("Creación de cuenta y acceso a la plataforma CFL", "Gratis"),
  createData("Consulta e inversión en productos de CFL", "Gratis"),
  createData("Asesoría por apoyo en inversión en CFL", "Gratis"),
  createData(
    "Coneccion con la cuenta de banco del cliente para hacer transferencias bancarias directas a la cuenta de Capital Funding Lab",
    "Gratis"
  ),
  createData("Análisis y asesoría de manejo de cuenta", "Gratis"),
  createData(
    "Retiro de fondos",
    "Gratis (excepto si el retiro es antes de la fecha acordada por el cliente en el contrato) *Ver detalle de productos de inversión"
  ),
  createData("Comisión por Administración", "Véase Fórmula"),
  createData("Comisión de Retiro", "Véase Fórmula")
]
export default function Contact() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Terminos de uso</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Comisiones a los Solicitantes:</b>
            </Typography>
          </Box>
          <Box py={2}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Servicio a otorgar</StyledTableCell>
                    <StyledTableCell align="center">
                      Costo al Cliente Solicitante de Crédito
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.cost}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box mt={2} py={2}>
            <Typography variant="h1" component="h1" gutterBottom>
              <b>Comisiones a los Inversionistas:</b>
            </Typography>
          </Box>
          <Box mb={4} py={2}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Servicio a otorgar</StyledTableCell>
                    <StyledTableCell align="center">
                      Costo al Cliente Inversionista
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsInvest.map(row => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.cost}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box mt={4} py={2} id="borrowerQuestions">
            <Typography variant="body1" gutterBottom inline>
              <strong>La Comisión de Retiro:</strong> solo se aplica si se
              retira antes de la fecha acordada en el contrato.
            </Typography>
            <Typography variant="body1" gutterBottom inline>
              La Tarifa de Retiro es aplicable únicamente para Uno y Mercado, y
              únicamente si los Usuarios solicitan retirar sus fondos con
              anterioridad al plazo definido para los productos. Se aplica una
              penalización proporcional al resultado del producto del tiempo
              transcurrido en días entre la fecha de solicitud de retiro de
              fondos y la fecha de finalización, y el valor presente de los
              préstamos el Usuario ha solicitado retirar. La Tarifa de retiro se
              calcula de acuerdo a la siguiente expresión:
            </Typography>
          </Box>
          <Box py={2} id="borrowerQuestions" alignItems="center">
            <img src={formula} alt="formula.png" />
          </Box>
          <Box mb={4} py={2} id="borrowerQuestions">
            <Typography variant="body1" gutterBottom inline>
              En el caso de que la tarifa de retiro fuese negativa, la misma no
              sería de aplicación.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
